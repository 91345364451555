import React from 'react'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'
import Nav from '../components/NavSub'
import Layout from '../components/layout'
import Header from '../components/Header'
import coverPhoto from '../assets/images/banner-13.jpg'

class ServicesMediations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: true
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }));
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  }

  render() {

    return (
      <Layout>
        <Helmet>
          <title>Mediation - Carien Zeeman</title>
          <meta name="description" content="Social Worker in Private Practice" />
          <meta name="keywords" content="carien zeeman,forensic social worker,care and contact evaluations,child assessments,forensic investigations,mediation,supervised visitation,therapy,training" />
        </Helmet>

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        >
        </Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="info" className="main">
            <div className="content sub-section">
              <header className="major">
                <h2>Mediation</h2>
              </header>
              <span className="image main">
                <img src={coverPhoto} alt="" />
              </span>
              <p>By acting as a neutral third party, Carien provides mediation services to families involved in family and divorce disputes. As established in the Children’s Act 38 of 2005, mediation is seen as a suitable manner of dispute and conflict resolution, as it is a process in which parties are supported by a third party in order to explore additional possibilities of resolving issues and/or to conduct situations.</p>

              <p>Mediation services can entail the following:</p>
              <li>Mediating in a non-adversarial way to reach an agreement arising from divorce disputes</li>
              <li>Providing Parenting Plans with a descriptive guideline for parents regarding their parental rights and responsibilities. </li>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default ServicesMediations
